<!--
  PACKAGE_NAME : src/pages/cc/ivr/notice/modal-dnis.vue
  FILE_NAME : modal-dnis
  AUTHOR : hmlee
  DATE : 2024-09-12
  DESCRIPTION : 대표번호 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div class="flex flex-row">
        <div class="basis-2/5 bg-white relative">
          <div class="flex flex-row absolute top-5 z-50">
            <h2 class="text-lg font-semibold">
              {{ $_msgContents('COMPONENTS.NOT_SETTING', { defaultValue: '미설정' }) }}
              {{ $_msgContents('CC.WORD.MAIN_NUMBER', { defaultValue: '대표번호' }) }}
            </h2>
            <button class="mdi mdi-help-circle ml-1 text-xl" id="dnisTooltip" @mouseenter="setTooltips" @mouseleave="setTooltips" />
            <DxPopover
              target="#dnisTooltip"
              :position="tooltip.position"
              :visible="tooltip.isVisible"
              :close-on-outside-click="tooltip.closeOnOutsideClick"
            >
              <span v-html="tooltip.contents"></span>
            </DxPopover>
            <DxButton
              template="<span class='mdi mdi-sync'></span>"
              :height="20"
              type="button"
              :hint="$_msgContents('CC.MESSAGE.RESET_MAIN_NUMBER', { defaultValue: '대표번호 설정 초기화' })"
              class="btn_XXXS white outlined ml-2"
              @click="onReset"
            />
          </div>
          <esp-dx-data-grid calss="relative" :data-grid="beforeDataGrid" :ref="beforeDataGrid.refName" @row-click="onClickSelectedRow" />
        </div>
        <div class="basis-1/5 flex flex-col justify-center px-5 space-y-4">
          <DxButton
            template="<span class='mdi mdi-chevron-right'></span>"
            :height="20"
            type="button"
            :hint="$_msgContents('COMPONENTS.ADD_SETTING', { defaultValue: '설정 추가' })"
            :disabled="button.disabled.clickedArrowType === 2"
            class="btn_XXXS white outlined"
            @click="onSetSelectedDnis"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-left'></span>"
            :height="20"
            type="button"
            :hint="$_msgContents('COMPONENTS.REMOVE_SETTING', { defaultValue: '설정 해제' })"
            :disabled="button.disabled.clickedArrowType === 1"
            class="btn_XXXS white outlined"
            @click="onRemoveSelectedDnis"
          />
        </div>
        <div class="basis-2/5 bg-white relative">
          <h2 class="text-lg font-semibold absolute top-5 z-50">
            {{ $_msgContents('COMPONENTS.SETTING', { defaultValue: '설정' }) }}
            {{ $_msgContents('CC.WORD.MAIN_NUMBER', { defaultValue: '대표번호' }) }}
          </h2>
          <esp-dx-data-grid :data-grid="afterDataGrid" :ref="afterDataGrid.refName" @row-click="onClickSelectedRow" />
        </div>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
        width: '120',
        height: '40',
        useSubmitBehavior: true,
        onClick: this.saveModal,
      }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
        width: '120',
        height: '40',
        onClick: this.closeModal,
      }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxPopover } from 'devextreme-vue/popover';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;
  export default {
    components: {
      EspDxDataGrid,
      DxPopover,
      DxPopup,
      DxToolbarItem,
      DxButton,
    },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
      dnisData: {
        default: null,
        type: Object,
      },
    },
    watch: {},
    computed: {
      /** @description: 모달 타이틀 출력 여부 */
      showTitle() {
        return !!this.modal.title;
      },
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
          showClearButton: true,
          width: '100%',
        },
        modal: {
          title: this.$_msgContents('CC.WORD.MAIN_NUMBER_SETTING', { defaultValue: '대표번호 설정' }),
          minWidth: '300',
          width: '900',
          minHeight: '300',
          height: '580',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          hideOnOutsideClick: false,
        },
        tooltip: {
          isVisible: false,
          position: 'top',
          closeOnOutsideClick: false,
          contents: `이미 다른 공지사항이 설정된 대표번호는<br>
                  목록에서 노출되지 않습니다.`,
        },
        button: {
          disabled: {
            clickedArrowType: 0, // 0: 초기화(화살표 버튼 안눌렀을 때), 1: 설정추가(오른쪽화살표), 2: 설정해제(왼쪽화살표)
          },
        },
        dnisSettingChangedRows: [], //설정 대표번호에 설정 추가된(변경된 로우) 데이터
        beforeDataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'beforeIvrDnisGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+ivrDnisOrd',
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: false,
            delete: false,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'ivrDnisOrd',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{  // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            filtering: true,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              i18n: 'COMMON.WORD.SITE',
              dataField: 'siteId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenantId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
            },
            {
              caption: 'DNIS',
              dataField: 'dnis',
              alignment: 'center', // left center right
              visible: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '대표번호',
              i18n: 'CC.WORD.MAIN_NUMBER',
              dataField: 'dnisNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
        afterDataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'afterIvrDnisGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+ivrDnisOrd',
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: false,
            delete: false,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'ivrDnisOrd',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{  // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            filtering: true,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              i18n: 'COMMON.WORD.SITE',
              dataField: 'siteId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT',
              dataField: 'tenantId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
            },
            {
              caption: 'DNIS',
              dataField: 'dnis',
              alignment: 'center', // left center right
              visible: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '대표번호',
              i18n: 'CC.WORD.MAIN_NUMBER',
              dataField: 'dnisNm',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 대표번호 툴팁 이벤트 */
      setTooltips() {
        this.tooltip.isVisible = !this.tooltip.isVisible;
      },
      /** @description: 모달 저장 */
      async saveModal() {
        //대표번호 설정이 없는 경우
        if (this.button.disabled.clickedArrowType === 0) {
          return vm.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        let dnisNoticeDatas = [];
        if (this.button.disabled.clickedArrowType === 1) {
          //대표번호 설정 추가
          dnisNoticeDatas = this.dnisSettingChangedRows.map(data => {
            return {
              ...data,
              noticeId: this.dnisData.id,
            };
          });
        } else if (this.button.disabled.clickedArrowType === 2) {
          //대표번호 설정 해제(삭제)
          dnisNoticeDatas = this.dnisSettingChangedRows.map(data => {
            return {
              ...data,
              noticeId: null,
            };
          });
        }

        const payload = {
          actionname: 'CC_IVR_DNIS_MERGE',
          data: dnisNoticeDatas,
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$emit('saveModal');
        }
      },
      /** @description: 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },
      /** @description: 미설정 대표번호 데이터 조회
       *
       * @param sort
       * @returns {Promise<void>}
       */
      async selectBeforeDataList(sort = '+ivrDnisOrd') {
        let vm = this;

        this.beforeDataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            const payload = {
              actionname: 'CC_IVR_DNIS_LIST',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              const rstData = res.data.data.filter(dnis => dnis.noticeId === null);
              rtnData = {
                data: rstData,
                totalCount: rstData.length,
              };
              vm.$refs[vm.beforeDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /* * @description: 설정 대표번호 데이터 조회
       * @param sort
       * @returns {Promise<void>}
       */
      async selectAfterDataList(sort = '+ivrDnisOrd') {
        let vm = this;

        this.afterDataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //공지사항으로 설정 대표번호
            params.noticeId = vm.dnisData.id;

            const payload = {
              actionname: 'CC_IVR_DNIS_LIST',
              data: params,
              useErrorPopup: true,
            };

            const res = await vm.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs[vm.afterDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 대표번호 설정 초기화 */
      onReset(e) {
        //그리드 편집 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //그리도 선택 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.deselectAll();
        this.$refs[this.afterDataGrid.refName].getGridInstance.deselectAll();

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].refreshData();
        this.$refs[this.afterDataGrid.refName].refreshData();

        //설정/해제 버튼 초기화
        this.button.disabled.clickedArrowType = 0;

        //설정 dnis에 추가된 데이터 초기화
        this.dnisSettingChangedRows = [];
      },
      /** @description: 그리드 클릭시 체크되는 이벤트 */
      onClickSelectedRow(e) {
        const selectedRowKeys = e.component.getSelectedRowKeys();
        const index = selectedRowKeys.indexOf(e.key);

        if (index === -1) {
          //선택되지 않은 경우 추가
          selectedRowKeys.push(e.key);
        } else {
          //선택된 경우 삭제
          selectedRowKeys.splice(index, 1);
        }

        e.component.selectRows(selectedRowKeys);
      },
      /** @description: 대표번호 설정 추가 */
      onSetSelectedDnis(e) {
        //미설정 대표번호 선택된 데이터
        const selectedRowsData = this.$refs[this.beforeDataGrid.refName].selectedRowsData;

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 추가 버튼 클릭시
        this.button.disabled.clickedArrowType = 1;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //설정 대표번호에 추가
        this.dnisSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          const rowIdx = this.$refs[this.afterDataGrid.refName].getGridInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.afterDataGrid.refName].getGridInstance.cellValue(index, key, value);
          });
        });

        let dnisSettingDatas = this.$refs[this.afterDataGrid.refName].getGridInstance;
        dnisSettingDatas = this.$refs[this.afterDataGrid.refName].getGridInstance.getVisibleRows();

        //미설정 대표번호에서 선택한 데이터 삭제
        let keys = selectedRowsData.map(item => {
          return this.$refs[this.beforeDataGrid.refName].getGridInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.beforeDataGrid.refName].getGridInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 대표번호 설정 해제 */
      onRemoveSelectedDnis(e) {
        //설정 대표번호 선택된 데이터
        const selectedRowsData = this.$refs[this.afterDataGrid.refName].selectedRowsData;

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 해제 버튼 클릭시
        this.button.disabled.clickedArrowType = 2;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getGridInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getGridInstance.cancelEditData();

        //미설정 대표번호에 추가
        this.dnisSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.beforeDataGrid.refName].getGridInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.beforeDataGrid.refName].getGridInstance.cellValue(index, key, value);
          });
        });

        //설정 대표번호에서 선택한 데이터 삭제
        let keys = selectedRowsData.map(item => {
          return this.$refs[this.afterDataGrid.refName].getGridInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.afterDataGrid.refName].getGridInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        await this.selectBeforeDataList(); //미설정 대표번호 조회
        await this.selectAfterDataList(); //설정 대표번호 조회
      },
      /** @description: 라이프사이클 mounted시 호출되는 메서드 */
      mountedData() {},
      /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {},
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>